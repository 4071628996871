var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { xs: "12", lg: "12" } },
            [
              _c(
                "b-card",
                [
                  _c(
                    "div",
                    [
                      _c("h4", { staticClass: "d-inline" }, [
                        _c("i", { staticClass: "fal fa-folder" }),
                        _vm._v(" All Projects")
                      ]),
                      _c(
                        "router-link",
                        {
                          staticClass: "float-right btn btn-success",
                          attrs: { to: { name: "Manager Add Project" } }
                        },
                        [
                          _vm._v("Add Project "),
                          _c("i", { staticClass: "fa fa-plus" })
                        ]
                      )
                    ],
                    1
                  ),
                  _c("hr", { staticClass: "clearfix my-4" }),
                  _c("v-client-table", {
                    attrs: {
                      columns: _vm.columns,
                      data: _vm.projectData,
                      options: _vm.options,
                      theme: _vm.theme,
                      id: "dataTable"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "status",
                        fn: function(props) {
                          return _c("div", {}, [
                            props.row.status === 0
                              ? _c("span", [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-success w-100"
                                    },
                                    [_vm._v("Active")]
                                  )
                                ])
                              : _vm._e(),
                            props.row.status !== 0
                              ? _c("span", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-sm btn-danger w-100"
                                    },
                                    [_vm._v("Inactive")]
                                  )
                                ])
                              : _vm._e()
                          ])
                        }
                      },
                      {
                        key: "name",
                        fn: function(props) {
                          return _c(
                            "span",
                            {},
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "Company",
                                      params: { id: props.row.companyid }
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(props.row.name))]
                              )
                            ],
                            1
                          )
                        }
                      },
                      {
                        key: "p_start_date",
                        fn: function(props) {
                          return _c("span", {}, [
                            _vm._v(
                              _vm._s(
                                _vm._f("moment")(
                                  props.row.p_start_date,
                                  "MM/DD/YYYY"
                                )
                              )
                            )
                          ])
                        }
                      },
                      {
                        key: "creation_date",
                        fn: function(props) {
                          return _c("span", {}, [
                            _vm._v(
                              _vm._s(
                                _vm._f("moment")(
                                  props.row.creation_date,
                                  "MM/DD/YYYY"
                                )
                              )
                            )
                          ])
                        }
                      },
                      {
                        key: "actions",
                        fn: function(props) {
                          return _c(
                            "div",
                            {},
                            [
                              _c(
                                "b-btn",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.editProject",
                                      modifiers: { editProject: true }
                                    }
                                  ],
                                  attrs: {
                                    size: "sm",
                                    variant: "secondary btn"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.sendInfo(props.row)
                                    },
                                    ok: function($event) {}
                                  }
                                },
                                [
                                  _vm._v("Edit "),
                                  _c("i", { staticClass: "fa fa-edit" })
                                ]
                              )
                            ],
                            1
                          )
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "editModal",
          attrs: {
            id: "editProject",
            title: "Edit Project",
            "title-tag": "h2",
            centered: "",
            size: "lg",
            "hide-footer": ""
          }
        },
        [
          _c(
            "form",
            {
              staticClass: "pb-2",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.validateBeforeSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c("label", [_vm._v("Project Name")]),
              _c(
                "b-input-group",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-input-group-prepend",
                    [
                      _c("b-input-group-text", [
                        _c("i", { staticClass: "fa fa-user" })
                      ])
                    ],
                    1
                  ),
                  _c("b-form-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "projectname",
                      placeholder: "Project Name"
                    },
                    model: {
                      value: _vm.projectname,
                      callback: function($$v) {
                        _vm.projectname = $$v
                      },
                      expression: "projectname"
                    }
                  })
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("projectname"),
                    expression: "errors.has('projectname')"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("projectname"),
                      expression: "errors.has('projectname')"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v("The project name is required.")]
              ),
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Project Start Date")
              ]),
              _c(
                "b-input-group",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-input-group-prepend",
                    [
                      _c("b-input-group-text", [
                        _c("i", { staticClass: "fa fa-calendar" })
                      ])
                    ],
                    1
                  ),
                  _c("datepicker", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "rt-date-picker w-50",
                    class: { "is-danger": _vm.errors.has("p_start_date") },
                    attrs: {
                      "use-utc": false,
                      name: "p_start_date",
                      placeholder: "Dropdown",
                      format: "yyyy-MM-dd"
                    },
                    model: {
                      value: _vm.p_start_date,
                      callback: function($$v) {
                        _vm.p_start_date = $$v
                      },
                      expression: "p_start_date"
                    }
                  })
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("p_start_date"),
                    expression: "errors.has('p_start_date')"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("p_start_date"),
                      expression: "errors.has('p_start_date')"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v("The project start date is required.")]
              ),
              _c(
                "b-input-group",
                { staticClass: "mt-3 mb-2" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "ml-2 mt-1",
                      attrs: { value: "0", "unchecked-value": "1" },
                      model: {
                        value: _vm.status,
                        callback: function($$v) {
                          _vm.status = $$v
                        },
                        expression: "status"
                      }
                    },
                    [
                      _c("p", { staticClass: "ml-3 mb-0" }, [
                        _vm._v("Project "),
                        _vm.status == 0
                          ? _c("span", [_vm._v("Active")])
                          : _vm._e(),
                        _vm.status != 0
                          ? _c("span", [_vm._v(" Inactive")])
                          : _vm._e()
                      ])
                    ]
                  )
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.error,
                    expression: "error"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle mt-3"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.error,
                      expression: "error"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v("Error. Please contact admin. ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-4",
                  attrs: { variant: "success", type: "submit", block: "" }
                },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }